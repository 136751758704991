.navbar-main-container {
    position: fixed;
    background-color: black;
    height: 9vh;
    max-height: 9vh;
    width: 100vw;
}

.navbar-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5vh 1vw;
}

.navbar-lef-content {
    
}

.navbar-right-content {
    display: flex;
    align-items: center;
    
}

.navbar-company-logo {
    height: 8vh;
    width: auto;
    cursor: pointer;
}

.navbar-start-new-game-btn {
    padding: 10px 20px;
    background-color: var(--green-main);;
    color: black;
    font-size: 1.3rem;
    font-weight: 700;
    border-radius: 8px;
    border: 0px;
    cursor: pointer;
}

.navbar-start-new-game-btn:hover {
    background-color: white;
    color: black;
    transition: ease 200ms;
}