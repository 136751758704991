.create-game-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.create-game-form-container {
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 30vh;
    border-radius: 16px;
}

.create-game-form-input {
    font-size: 1.2rem;
    padding: 12px 20px;
    border: 2px solid lightgray;
    border-radius: 8px;
    margin-bottom: 5vh;
}

.create-game-btn {
    padding: 10px 20px;
    background-color: var(--green-main);;
    color: black;
    font-size: 1.3rem;
    font-weight: 700;
    border-radius: 8px;
    border: 0px;
    cursor: pointer;
}