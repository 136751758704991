.ModalBackground-player-info {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: -10px;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: white;
}

.add-course-form-main-container {
    /* width: 50vw; */
    height: auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.add-course-form-container {
    width: 40vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 2vh 2vw 5vh 2vw;
}

.add-course-form-title {
    font-size: 25px;
    font-weight: 700;
    color: black;
    text-align: left;
}

.add-course-form-inner-container {
    margin-top: 3vh;
}

.add-course-form-items {

}

.add-course-form-item {
    text-align: center;
    color: black;
    font-weight: 400;
    padding: 15px 0 15px 0;
    width: 100%;
    border: 2px solid lightgrey;
    border-radius: 10px;
    font-size: 1rem;
}

.add-course-btns {
    margin-top: 5vh;
    margin-bottom: 2vh;
}

.add-course-btn-create {
    padding: 1rem;
    width: 100%;
    background-color: var(--green-main);
    border: 2px solid var(--green-main);
    color: black;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.user-detail-close-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user-detail-close-icon {
    height: 30px;
    width: auto;
    cursor: pointer;
}


@media (max-width: 600px) {
    .add-course-form-container {
        width: 80vw;
        margin: 2vh 4vw 2vh 4vw;
    }

    .add-course-form-title {
        font-size: 20px;
        text-align: center;
    }

    .add-course-btn-create {
        padding: 1rem;
        width: 100%;
        background-color: var(--green-main);
        border: 2px solid var(--green-main);
        color: black;
        font-size: 1rem;
        font-weight: 700;
        border-radius: 10px;
        cursor: pointer;
    }
}