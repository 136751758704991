.ModalBackground {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: -10px;
    right: 0;
    bottom: -10px;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: white;
}

.renew-session-main-container {
    height: auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.renew-session-form-container {
    width: 40vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 2vh 2vw 5vh 2vw;
}

.renew-session-form-inner-container {
    margin-top: 0;
}

.renew-session-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
}

.renew-session-form-title {
    font-size: 25px;
    font-weight: 700;
    color: black;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

.renew-session-clock-icon {
    height: 10vh;
    width: auto;
}

.renew-session-warning-icon {
    height: 5vh;
    width: auto;
}

.renew-session-btns {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.renew-session-btn-create {
    padding: 1rem;
    width: 100%;
    background-color: var(--green-main);
    border: 2px solid var(--green-main);
    color: black;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.renew-session-btn-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: center;
}
