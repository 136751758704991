.scrum-poker-app-nav-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 8vh;
    max-height: 8vh;
}

.scrum-poker-app-nav-inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90vw;
    justify-content: space-between;
}

.scrum-poker-app-nav-left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scrum-poker-app-nav-logo {
    height: 7vh;
    cursor: pointer;
}

.scrum-poker-app-nav-game-name {
    font-size: 1.3rem;
    font-weight: 700;
    color: black;
    cursor: pointer;
}

.scrum-poker-app-nav-right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scrum-poker-app-nav-player-name {
    font-size: 1.3rem;
    font-weight: 700;
    color: black;
    margin-right: 30px;
    cursor: pointer;
}

.scrum-poker-app-nav-invite-player-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: white;;
    color: var(--green-main);
    font-size: 1.3rem;
    font-weight: 700;
    border-radius: 8px;
    border: 2px solid var(--green-main);
    cursor: pointer;
}

.scrum-poker-app-nav-invite-player-btn:hover {
    background-color: black;
}

.scrum-poker-app-invite-player-icon {
    height: 3vh;
    margin-right: 5px;
}

/* Contenedor Medio */

.scrum-poker-app-lonely-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scrum-poker-app-lonely-txt {
    font-size: 1rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.scrum-poker-app-lonely-btn {
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--green-main);
    cursor: pointer;
}

.scrum-poker-app-lonely-btn:hover {
    color: rgba(178,255,46, 0.8)
}

.scrum-poker-app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 92vh;
    width: 100vw;
}

.scrum-poker-app-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 90vw;
}

.scrum-poker-app-desk {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30vw;
    height: 20vh;
    background-color: var(--green-main);
    border-radius: 2rem;
    margin-left: 2vw;
    margin-right: 2vw;
}

.scrum-poker-app-reveal-cards-btn {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 1vh 2vw;
    border-radius: 16px;
    border: 0px;
    background-color: black;
    color: white;
    cursor: pointer;
}

.scrum-poker-app-reveal-cards-btn:hover {
    background-color: gray;
}

.scrum-poker-app-top-row-players {
    display: flex;
    flex-direction: row;
    margin-bottom: 3vh;   
    justify-content: center;
}

.scrum-poker-app-bottom-row-players {
    display: flex;
    flex-direction: row; 
    margin-top: 3vh;   
    justify-content: center;
}

.scrum-poker-app-player-full-card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.scrum-poker-app-player-name {
    font-size: 1rem;
    font-weight: 400;
    color: black;
}

.scrum-poker-app-player-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5vw;
    height: 10vh;
    background-color: lightgrey;
    border-radius: 10px;

    -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
}

.scrum-poker-app-player-card-flipped {
    background: linear-gradient(45deg, #64b5f6, var(--green-main)); 
    -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
}

.scrum-poker-app-player-card-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5vw;
    height: 10vh;
    background-color: var(--green-main);
    border-radius: 10px;
}

.scrum-poker-app-card-logo{
    width: 3vw;
    height: auto;
}

.scrum-poker-app-middle-row-player {
    display: flex;
    flex-direction: row;
}

.scrum-poker-app-side-row-players {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/*   */

.scrum-poker-app-footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
}
.scrum-poker-app-player-pick-cards-text {
    font-size: 16px;
    font-weight: 400;
}

.scrum-poker-app-player-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}


@media (max-width: 600px) {

    .scrum-poker-app-nav-game-name {
        font-size: 1.3rem;
        font-weight: 700;
        margin-left: 10px;
    }

    .scrum-poker-app-nav-player-name {
        display: none;
    }

    .scrum-poker-app-nav-invite-player-btn {
        font-size: 0;
    }


    .scrum-poker-app-desk {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50vw;
        height: 10vh;
        background-color: var(--green-main);
        border-radius: 1.5rem;
        margin-left: 2vw;
        margin-right: 2vw;
    }

    .scrum-poker-app-player-card {
        width: 10vw;
        height: 10vh;
    }

    .scrum-poker-app-player-cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        cursor: pointer;
    }

    .scrum-poker-app-player-pick-cards-text {
        margin-bottom: 3vh;
    }

  }