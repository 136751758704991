

.scrum-poker-app-player-vote-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7vw;
    height: 17vh;
    background-color: white;
    border-radius: 10px;
    margin-right: 10px; 
}

.scrum-poker-app-player-vote-card-selected {
    /* padding: 1vh 0 1vh 0; */
    background-color: var(--green-main);
}

.scrum-poker-app-player-inner-vote-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7vw;
    height: 16vh;
    background-color: var(--green-main);
    border-radius: 15px;
}

.scrum-poker-app-player-inner-vote-card-selected {
    padding: 1vh 0 1vh 0;
    background-color:white;
    border: 2px solid var(--green-main);
}

.scrum-poker-app-player-vote-card-value {
    font-size: 1.5rem;
    font-weight: 700;
}

.scrum-poker-app-player-vote-card:hover {
    background-color: black; 
}

@media (max-width: 600px) {
    .scrum-poker-app-player-vote-card {
       background-color: black;
       height: max-content;
       width: max-content;
       margin-bottom: 1vh;
    }

    .scrum-poker-app-player-inner-vote-card {
        height: 8vh;
        width: 10vw;
    }

    .scrum-poker-app-player-vote-card-value {
        font-size: 1rem;
        font-weight: 700;
    }

    .scrum-poker-app-player-inner-vote-card-selected {
        padding: 0;
    }
 }