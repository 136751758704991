.ModalBackground-copied {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: -10px;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    color: white;
}

.copied-modal-main-container {
    min-width: 50vw;
    /* min-height: 30vh; */
    height: auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.copied-modal-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vh 2vw;
}

.copied-modal-close-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.copied-modal-text {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0;
    color: black;
}

.copied-modal-btn {
    padding: 10px 20px;
    background-color: var(--green-main);;
    color: black;
    font-size: 1.3rem;
    font-weight: 700;
    border-radius: 8px;
    border: 0px;
    cursor: pointer;
    margin-bottom: 2vh;
}


@media (max-width: 600px) { 
    .copied-modal-main-container {
        width: 80vw;
    }

    .copied-modal-inner-container {
        margin: 2vh 4vw;
    }

    .copied-modal-text {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 0;
        color: black;
    }
}